footer {
    background: $bg-color;
    display: flex; }

.powered-by-text {
    text-align: center;
    padding: 0 0 2rem 0; }

.footer-inner {
    display: flex;
    gap: 300px;
    padding: 8rem 3rem;

    @media (max-width: 1300px) {
        gap: 100px; }

    @media (max-width: #{map-get($breakpoints, l)} ) {
        flex-direction: column;
        gap: 40px; }

    table td:nth-child(even) {
        padding-left: 1.5rem; }

    .footer-inner-address {
        padding-bottom: 2rem; }

    span {
        display: flex;
        gap: 5px;
        align-items: center;
        padding-bottom: 1rem;

        svg {
            height: 25px;
            width: 25px; } }

    .footer-ssl-badge-wrapper {
        padding: 3rem 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        .ssl-badge-text {
            @media (max-width: #{map-get($breakpoints, xl)} ) {
                width: 50%; }
            @media (max-width: #{map-get($breakpoints, m)} ) {
                width: 100%; }
            p {
                margin: 0;
                padding: 0;
                color: $dark;
                font-size: 1.2rem;
                line-height: 1.6; } }
        .ssl-badge {
            width: 12rem;
            height: auto;
            margin-left: 1.5rem;
            @media (max-width: #{map-get($breakpoints, xl)} ) {
                width: 8rem; } } } }

#footer-menu-id {
    list-style: none;
    padding-left: 0;

    .active {}
    a {
        color: $dark; }

    a {
        color: $dark;

        &:hover {
            color: $dark; } } }

.block.footer-smart-phone-block {
    border-top: 1px solid #c3c3c3;
    position: relative;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    margin: 0;
    padding: 5rem 0;
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: linear-gradient(238deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%); }
    .footer-smart-phone-inner {
        display: flex;
        @media (max-width: #{map-get($breakpoints, m)} ) {
            flex-direction: column;
            align-items: center; } }
    @media (max-width: #{map-get($breakpoints, m)} ) {
        p {
            font-size: 1.5rem; }
        h2 {
            font-size: 2.2rem;
            margin-bottom: 2rem; } }

    .footer-smart-phone-logo-wrapper {
        width: 200px;
        position: relative;
        z-index: 1;
        @media (max-width: #{map-get($breakpoints, m)} ) {
            margin-bottom: 25px;
            display: flex;
            justify-content: center; }
        img {
            width: 70%;
            height: auto;
            object-fit: contain;
            @media (max-width: #{map-get($breakpoints, m)} ) {
                width: 50%; } } }

    .footer-smart-phone-text-wrapper {
        width: calc(100% - 200px);
        position: relative;
        z-index: 1;
        @media (max-width: #{map-get($breakpoints, m)} ) {
            width: 100%; } }
    .footer-smart-phone-logos-wrapper {
        display: flex;
        padding: 25px 0;
        .footer-smart-phone-logo {
            width: auto;
            height: 50px;
            margin-right: 15px;
            transition: $transition;
            &:hover {
                opacity: 0.66;
                transition: $transition; } } } }

.block.footer-partner-block {
    margin: 0;
    border-top: 1px solid #c3c3c3;
    padding: 5rem 0;
    h2 {
        text-align: center; }
    .footer-partner-icon-wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: 0 0 50px 0;
        justify-content: center;
        .footer-partner-logo-container {
            width: 250px;
            height: 100px;
            margin: 15px;

            @media (max-width: 600px) {
                margin: 0; }

            @media (max-width: 550px) {
                width: 200px; }
            @media (max-width: 450px) {
                width: 160px; }

            img {
                width: 80%;
                height: 80%;
                object-fit: contain; } } } }


.sticky-whatsapp {
  position: fixed;
  right: 4rem;
  bottom: 6rem;
  background-color: $primary;
  height: 5.5rem;
  width: 5.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px rgba($primary, 0.16);
  transition: $transition;
  z-index: 99;

  @media (max-width: #{map-get($breakpoints, s)}) {
    height: 4.5rem;
    width: 4.5rem;
    right: 2rem;
    bottom: 2rem; }

  &:hover {
    background-color: #2d8149;
    transition: $transition; }

  svg {
    height: 2.8rem;
    width: 2.8rem;
    fill: $light;

    @media (max-width: #{map-get($breakpoints, s)}) {
      height: 2.2rem;
      width: 2.2rem; } } }
