.block-contact-map {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;


    .map-socials {
        background-color: $primary;
        padding: 4rem 2rem;

        * {
            color: $light; }

        .global-wrapper {
            display: flex;
            gap: 40px;

            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                flex-direction: column;
                align-items: center;
                gap: 10px; } }

        svg {
            height: 35px;
            width: 35px;
            fill: $light; }

        a {
            display: inline-flex;
            align-items: center;
            gap: 10px;
            font-size: 2rem;

            &:hover {
                color: $light; }

            &:first-of-type {
                margin-right: 2rem; } } }


    .map-wrapper {
        height: 300px;
        position: relative;
        left: 0;

        .contact-map {
            height: 100%;
            width: 100%;
            border: 0;
            margin: 0;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0; } }

    //         &::after
    //             height: 100%
    //             content: ''
    //             display: block
    //             z-index: 3
    //             position: absolute
    //             left: 0
    //             top:
    //             bottom: 0
    //             right: 50%
    //             background-image: linear-gradient(to right, #fff 0%, rgba(242, 242, 242, 0) 100%)
    //             background-repeat: repeat-x
    //             pointer-events: none

    //         @media only screen and (max-width: #{map-get($breakpoints, l)})
    //             &::after
    //                 background-image: none


    // .contact-wrapper
    //     @media only screen and (max-width: #{map-get($breakpoints, l)})
    //         @include padding-container
    //         @include padding-vertical
    //     .btn-route
    //         text-transform: uppercase
    //         color: $primary

    //     .apo-info
    //         margin-top: 2rem

    //     .phone-link
    //         color: $primary
    //         text-decoration: none
    //         transition: 0.3s
    //         margin: 15px 0
    //         display: flex
    //         font-size: 2.3rem
    //         gap: 1rem
    //         align-items: center

    //         svg
    //             height: 28px
    //             width: 28px
    //             fill: $primary

    //     .contact-infos
    //         margin-top: 2rem

    //         p:last-of-type
 }    //             font-size: 1.8rem
