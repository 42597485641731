#popup-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(14, 13, 12, 0.7);
    z-index: 9999;
    display: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 1.8rem;

    &.show {
        display: block; }

    td {
        padding-right: 1rem; }

    h3 {
        padding-bottom: 1rem; }

    .advantages-popup {
        position: absolute;
        max-width: 475px;
        padding: 6rem;
        background-color: $light;
        border-radius: 40px;
        top: 35%;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: block;
        text-align: left;
        z-index: 99;
        box-shadow: 0 0 20px rgba(0,0,0,.16);


        h4 {
            @media (max-width: #{map-get($breakpoints, s)}) {
                margin-top: -1.5rem; } }

        p {
            font-weight: 400 !important; }

        @media (max-width: #{map-get($breakpoints, m)}) {
            padding: 5*$base-width;
            padding-top: 4*$base-width; }

        .button {
            padding: 0 6.25 * $base-width;
            position: relative;
            cursor: pointer;

            @media (max-width: #{map-get($breakpoints, m)}) {
                margin-bottom: 3 * $base-width; }

            &:after, &:before {
                position: absolute;
                right: -10px;
                content: '';
                height: 30px;
                width: 1px;
                top: -30px;
                background-color: $dark;

                @media (max-width: #{map-get($breakpoints, m)}) {
                    right: 0;
                    top: -7px;
                    height: 30px; } }

            &:after {
                transform: rotate(45deg); }

            &:before {
                transform: rotate(-45deg); } } } }
