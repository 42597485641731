.block-slider-app {

    .page-slider {
        position: relative;

        .owl-stage {
            padding-top: 3 * $base-width;
            padding-bottom: 3 * $base-width;
            display: flex;

            .owl-item {
                display: flex;

                .row {
                    display: flex;
                    flex-wrap: wrap;

                    .col-sm-3 {
                        flex: 0 0 25%;
                        width: 25%;

                        @media only screen and (max-width: #{map-get($breakpoints, sm)}) {
                            flex: 0 0 100%;
                            width: 100%; } }

                    .col-sm-9 {
                        flex: 0 0 75%;
                        width: 75%;

                        @media only screen and (max-width: #{map-get($breakpoints, sm)}) {
                            flex: 0 0 100%;
                            width: 100%; } }

                    .slider-text-content {
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        padding-left: 40px;
                        padding-right: 40px; } } } }

        .owl-nav {
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                top: 100%;
                justify-content: center; }

            button {
                transform: translateX(100%);

                @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                    transform: translateX(0%); }

                &.owl-prev {
                    transform: translateX(-100%);

                    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                        transform: translateX(0%); } } } } } }
