a {
    color: $primary;
    text-decoration: underline;
    transition: $transition;
    display: inline-block;

    &:hover {
        color: $secondary; }

    &:focus {
        outline: 0; } }

ul {
    padding-left: 5 * $base-width;

    li {
        margin-bottom: $base-width; } }

strong,
.bold {
    font-weight: 700; }

p {
    line-height: 1.6;
    padding-bottom: 3*$base-width; }

.headline-wrapper {
    &.align-center {
        text-align: center; } }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    display: block;
    color: $primary;
    font-family: $font-default; }

h1,
.h1 {
    margin-bottom: 3 * $base-width;
    font-size: 4.8rem;
    line-height: 135%;
    font-weight: 700;

    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        font-size: 4rem; }

    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        font-size: 3rem; } }

h2,
.h2 {
    margin-bottom: 3 * $base-width;
    font-size: 3.6rem;
    line-height: 140%;
    font-weight: 600;
    color: $primary;

    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        font-size: 2.9rem; } }

h3,
.h3 {
    font-size: 3rem;
    line-height: 130%;
    font-weight: 500;

    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        font-size: 2.5rem; } }

h4,
.h4 {
    font-size: 2.6rem;
    line-height: 125%;
    font-weight: 500;

    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        font-size: 2rem; } }
