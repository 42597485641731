.block.block-slider-magazine {

    .headline-wrapper {
        padding-bottom: 4 * $base-width;

        .h2 {
            text-align: center; } }

    .magazin-slider {
        position: relative;

        .owl-stage {
            padding-top: 3 * $base-width;
            padding-bottom: 3 * $base-width;
            display: flex;

            .owl-item {
                display: flex;

                .magazin-item {
                    box-shadow: $box-shadow;
                    transform: scale(1);
                    transition: transform .3s;
                    border-radius: 20px;
                    width: 100%;

                    &:hover {
                        transform: scale(1.02);
                        transition: transform .3s; }

                    a {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 100%; }


                    .teaser-img {
                        padding-top: 56%;
                        display: block;
                        position: relative;

                        img {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            object-fit: cover;
                            object-position: center;
                            height: 100%;
                            width: 100%;
                            border-radius: 20px 20px 0 0; } }


                    .teaser-body {
                        padding: 4 * $base-width;
                        min-height: 125px;
                        color: $dark;

                        .date-wrap {
                            margin-bottom: $base-width;

                            .date {
                                text-transform: uppercase;
                                font-size: 1.4rem; } }

                        .body-text {
                            font-size: 2rem;

                            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                                font-size: 1.8rem; }

                            p {
                                padding: 0; } } }

                    .button-wrap {
                        padding: 4 * $base-width 3 * $base-width; } } } }

        .owl-nav {
            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                top: 100%;
                justify-content: center; }

            button {
                transform: translateX(100%);

                @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                    transform: translateX(0%); }

                &.owl-prev {
                    transform: translateX(-100%);

                    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                        transform: translateX(0%); } } } } } }
