header {
    &.fixed-small {
        .navbar-meta {
            height: 0;
            opacity: 0;
            visibility: hidden;
            transition: $transition;

            .meta-nav-item {
                height: 0;
                opacity: 0;
                overflow: hidden;
                transition: $transition;

                a {
                    height: 0;
                    overflow: hidden; } } } }

    .navbar-meta {
        transition: $transition;
        height: 50px;
        // opacity: 1
        // visibility: visible
        display: flex;
        justify-content: center;
        align-items: center;
        @include padding-container;
        background-color: $primary;

        &:not(.navbar-meta-mobil) {
            .navbar-meta-inner {
                height: 5rem; } } }

    .navbar-meta-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-size: 1.4rem;
        font-weight: 600;

        ul {
            display: inline-flex;
            list-style: none;
            column-gap: 3rem;

            a {
                color: $light; } }

        .meta-nav-item {
            display: flex;
            column-gap: 1rem;
            align-items: center;

            img {
                width: 1.9rem;
                filter: $filter-light; }

            a {
                color: $light;
                font-size: 1.6rem;
                display: flex;
                column-gap: 1rem;
                display: inline-flex;
                justify-content: center;
                align-items: center;

                @media (max-width: #{map-get($breakpoints, s)} ) {
                    font-size: 1.4rem; }

                &.facebook {
                    border-radius: 100%;

                    img {
                        filter: unset;
                        height: 3rem;
                        width: 3rem; } }

                &.instagram {
                    img {
                        filter: unset;
                        height: 3rem;
                        width: 3rem; } }

                &.whatsapp {
                    img {
                        filter: unset;
                        height: 3rem;
                        width: 3rem; } } }

            .meta-hide-on-mobil {
                @media (max-width: #{map-get($breakpoints, l)} ) {
                    display: none; }

                &--social {
                    @media (max-width: #{map-get($breakpoints, xl)} ) {
                        display: none; } } } }


        .meta-nav-left, .meta-nav-right {
            display: flex;
            column-gap: 2rem; }

        .meta-nav-right {
            @media (max-width: #{map-get($breakpoints, s)} ) {
                column-gap: 1.5rem; } } } }



.navbar-meta.navbar-meta-mobil {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    margin-top: 3rem;
    padding: 2rem 0 0 0;
    background-color: $light;
    border-top: 1px solid #999;

    @media (min-width: #{map-get($breakpoints, l)} ) {
        display: none; }

    .navbar-meta-inner {
        align-items: flex-start;
        flex-direction: column; }

    .meta-nav-item {
        display: flex;
        margin-top: .5rem;

        p {
            display: block; } }

    .meta-hide-on-mobil {
        @media (max-width: #{map-get($breakpoints, l)} ) {
            display: flex; } }

    .meta-nav-left,
    .meta-nav-right, {
        display: block;

        a {
            color: $primary; }

        img {
            filter: $filter-primary; }


        .meta-hide-on-mobil {
            @media (max-width: #{map-get($breakpoints, l)} ) {
                display: flex; } } } }
