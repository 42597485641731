.block-archive-magazine {

    .headline-wrapper {
        padding-bottom: 4 * $base-width;

        .h2 {
            text-align: center; } }

    .magazin-list {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -3 * $base-width;

        .magazin-item {
            border-radius: 20px;
            flex: 0 0 33.333%;
            padding: 3 * $base-width;

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                flex: 0 0 50%; }

            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                flex: 0 0 100%; }

            .teaser-wrapper {
                box-shadow: $box-shadow;
                transform: scale(1);
                transition: transform .3s;
                border-radius: 20px;
                height: 100%; }

            &:hover {
                transform: scale(1.02);
                transition: transform .3s; }

            a {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%; }


            .teaser-img {
                padding-top: 56%;
                display: block;
                position: relative;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    object-fit: cover;
                    object-position: center;
                    height: 100%;
                    width: 100%;
                    border-radius: 20px 20px 0 0; } }


            .teaser-body {
                padding: 4 * $base-width;
                min-height: 125px;
                color: $dark;

                .date-wrap {
                    margin-bottom: $base-width;

                    .date {
                        text-transform: uppercase;
                        font-size: 1.4rem; } }

                .body-text {
                    font-size: 2rem;

                    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                        font-size: 1.8rem; }

                    p {
                        padding: 0; } } }

            .button-wrap {
                padding: 4 * $base-width 3 * $base-width; } } } }
