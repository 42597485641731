.block-slider-hero {
  padding: 0 0 8 * $base-width 0;

  @media only screen and (max-width: #{map-get($breakpoints, l)}) {
    padding: 0 0 4 * $base-width 0; }

  .hero-slider {
    &__content {
      width: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      color: $light;
      opacity: 0;
      z-index: 2;

      &-inner {
        width: 100%;
        max-width: $boxed;
        margin: 0 auto;
        @include padding-container;

        // @media only screen and (max-width: #{map-get($breakpoints, m)})
        //   padding-right: 0
        //   padding-left: 0

        > * {
          max-width: 40%;

          @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            max-width: 55%; }

          @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            max-width: 70%; }

          @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            max-width: 100%; } }

        .headline-wrapper {
          h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
            color: $light;
            margin-bottom: 2rem;
            font-weight: 500;
            font-size: 3.8rem;
            letter-spacing: 0.1rem;
            text-transform: uppercase;
            text-shadow: rgba(0, 0, 0, 0.4) 0 0 0.8rem;

            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
              font-size: 2.5rem;
              margin-bottom: $base-width; }

            @media only screen and (min-width: #{map-get($breakpoints, m)}) and (max-width: #{map-get($breakpoints, xl)}) {
              font-size: 3.2rem;
              margin-bottom: $base-width; } } }

        p {
          @media only screen and (max-width: #{map-get($breakpoints, l)}) {
            font-size: 1.6rem; }

          @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            padding-bottom: $base-width;
            font-size: 1.4rem; } }

        .btn {
          margin-top: 3*$base-width;

          @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            margin-top: $base-width; } } } }

    &__item-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      overflow: hidden;

      picture {
        display: block;
        width: 100%;
        height: 65vh;
        position: relative;

        @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
          height: 45vh; }

        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
          height: 50vh; }


        &::before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: black;
          opacity: 0.2;
          z-index: 1; }

        img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          object-fit: cover;
          object-position: center;
          transition: transform 10s; } } }

    .owl-item.active {
      .hero-slider__item-wrapper {
        img {
          transform: scale(1.15); }

        .hero-slider__content {
          animation: slideContent 0.4s ease-in-out 1.5s forwards; } } }

    .owl-nav {
      max-width: $boxed;
      position: absolute;
      z-index: 9;

      @media only screen and (max-width: #{map-get($breakpoints, xxxl)}) {
        top: unset;
        bottom: 15%;
        justify-content: flex-start;
        @include padding-container;

        button {
          margin-right: 20px; } }

      @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        bottom: 8%; } } } }



@keyframes slideContent {
  0% {
    transform: translateX(-100%);
    opacity: 0; }

  100% {
    transform: translateX(-50%);
    opacity: 1; } }
