a, button {
  background: transparent;
  border: 0;
  box-shadow: none;
  outline: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;

  &:hover, &:focus, &:active {
    outline: none;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent; } }

.btn {
  display: inline-block;
  padding: 1.5rem 3rem;
  font-size: 1.6rem;
  line-height: 1.2;
  font-weight: 700;
  transition: $transition;
  border-radius: 25px;
  cursor: pointer;

  &.btn-primary {
    color: $light;
    background-color: $primary;
    border: solid 1px $primary;

    &:hover {
      background-color: $light;
      border: 1px solid $primary;
      color: $primary; } }


  &.btn-secondary {
    color: $primary;
    background-color: $light;
    border: solid 1px $primary;

    &:hover {
      background-color: $primary;
      border: 1px solid $light;
      color: $light; } } }
