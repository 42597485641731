* {
    padding: 0;
    margin: 0;
    box-sizing: border-box; }

html {
    font-size: 10px; }

body {
    -webkit-text-size-adjust: none;
    font-size: 1.8rem;
    color: $dark;
    line-height: 160%;
    font-weight: 400;
    font-family: $font-default;
    overflow-x: hidden;

    &.nav-open-body-overlay {
        @media (max-width: #{map-get($breakpoints, l)}) {
            overflow: hidden;
            height: 100%;
            touch-action: none;
            block-size: 100%;
            pointer-events: none; }

        .main-nav-container {
            pointer-events: true;
            touch-action: true; } } }


img {
    display: block;
    max-width: 100%; }

main,
.block {
    position: relative; }

main {
    padding-top: 150px;

    @media (max-width: #{map-get($breakpoints, m)}) {
        padding-top: 138px; } }

a:not(.btn) {
    @media (max-width: #{map-get($breakpoints, sm)}) {
        overflow-wrap: break-word; } }

.block {
    width: 100%;
    @include padding-container;
    @include padding-vertical;
    position: relative; }

.bg-white + .bg-white {
    padding-top: 0; }

.wrapper {
    width: 100%;
    max-width: $boxed;
    margin: 0 auto; }

.wrapper-small {
    max-width: $small;
    margin: 0 auto;
    width: 100%; }

#svg-preparation {
    display: none;
    height: 0; }
