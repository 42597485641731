.block-banner {
    &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: $light;
        opacity: 0.2;
        z-index: -1; }

    img {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        z-index: -2; }

    .inner {
        position: relative;
        max-width: $small;
        margin: 0 auto;
        @include padding-vertical;
        @include padding-container;
        text-align: center;
        @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
            padding-top: 0;
            padding-bottom: 0; }

        .btn {
            margin-top: 3 * $base-width; } } }
