.block-mitarbeiter {
    .headline-wrapper {
        padding-bottom: 4 * $base-width;

        h1 {
            text-align: center; } }

    .teaser-wrapper {
        margin: 0 -3 * $base-width;
        display: flex;
        flex-wrap: wrap;


        .teaser-item {
            padding: 3 * $base-width;
            flex: 0 0 33.3333%;

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                flex: 0 0 50%; }

            @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                flex: 0 0 100%; }


            picture {
                padding-top: 70%;
                display: block;
                position: relative;
                border-radius: 20px 20px 0 0;
                overflow: hidden;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center; } }

            .info {
                padding: 3 * $base-width;
                background-color: $primary;
                border-radius: 0 0 20px 20px;
                overflow: hidden;

                h3 {
                    margin-bottom: $base-width; }

                a {
                    display: flex;
                    align-items: center;
                    margin-bottom: $base-width;

                    svg {
                        height: 22px;
                        width: 22px;
                        margin-right: $base-width; }

                    &:hover {
                        opacity: 0.8; } }

                h3, p, a {
                    color: $light; } } } } }
