.block.block-contact-boxes {
  h2 {
    text-align: center; }

  .info-boxes {
    &-wrapper {
      display: flex;
      width: 100%;
      max-width: $small;
      margin: 0 auto;

      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        flex-wrap: wrap;
        row-gap: 5*$base-width; } }

    &-item {
      flex: 0 0 33.33%;
      width: 33.33%;
      // padding: 0 2*$base-width

      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        flex: 0 0 50%;
        width: 50%; }

      @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        flex: 0 0 100%;
        width: 100%; }

      &:nth-child(3) {
        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
          flex: 0 0 100%;
          width: 100%; }

        .info-boxes__text {
          @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            column-count: 2;
            column-gap: 5*$base-width; }

          @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            column-count: unset;
            column-gap: unset; } } }

      &__inner {
        background-color: $light;
        border: 1px solid $primary;
        border-radius: 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 40px;
        transition: $transition;

        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
          padding: 25px; }

        .text-icon {
          gap: 1rem;
          margin-bottom: 2 * $base-width;

          svg {
            flex: 0 0 25px;
            width: 45px;
            height: 45px;
            object-fit: contain;
            fill: $primary;

            &.svg_check {
              width: 20px;
              height: 20px; }

            &.svg_phone, &.svg_fax {
              width: 22px;
              height: 22px; } }

          h3 {
            font-weight: 700;
            margin-top: 2 * $base-width; } }


        &:hover {
          background-color: $primary;
          transition: $transition;

          *:not(.btn) {
            color: $light; }

          svg {
            transition: $transition;
            fill: $light; }

          .btn-primary {
            background-color: $light;
            color: $primary; } }

        *:not(.btn) {
          color: $primary; }

        p, a, span, .info-boxes__openings, .text-icon {
          font-size: 1.8rem; }

        .btn {
          align-self: flex-start;
          margin-top: 1.5rem; }

        .info-boxes__text {
          flex: 1 auto;

          .text-icon, .info-boxes__openings {
            margin-bottom: 2*$base-width; }

          .text-icon {
            display: flex;
            align-items: center;
            gap: 1rem;

            svg {
              flex: 0 0 25px;
              width: 25px;
              height: 25px;
              object-fit: contain;
              fill: $primary;

              &.svg_check {
                width: 20px;
                height: 20px; }

              &.svg_phone, &.svg_fax {
                width: 22px;
                height: 22px; } } } } }

      &:nth-child(1) {
        .info-boxes-item__inner {
          border-radius: 20px 0px 0px 20px;

          @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            border-radius: 20px 20px 20px 20px; } } }

      &:nth-child(2) {
        .info-boxes-item__inner {
          border-radius: 0;

          @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            border-radius: 0px 20px 20px 0px; }

          @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            border-radius: 20px 20px 20px 20px; } } }

      &:nth-child(3) {
        .info-boxes-item__inner {
          border-radius: 0px 20px 20px 0px;

          @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            border-radius: 20px 20px 20px 20px; } } } } } }
