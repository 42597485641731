.block-hero-image {
    padding: 0;
    position: relative;
    padding-top: 30%;

    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        padding-top: 45%; }

    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding-top: 55%; }

    .wrapper {
        position: absolute;
        left: 0;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        z-index: 8;

        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            top: unset;
            bottom: 0;
            transform: unset;
            width: 100%; }

        .content {
            display: flex;
            justify-content: center;
            margin: 0 auto;

            .inner {
                padding: 2 * $base-width 4 * $base-width;
                background-color: rgba($primary, 0.8);

                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    width: 100%;
                    text-align: center; }

                h1 {
                    color: $light;
                    margin-bottom: 0; } } } }

    picture {
        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center; } } }
