.block-text-bild {
    @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
        padding-top: 0; }
    .wrapper {
        max-width: $small; }

    picture {
        position: relative;
        padding-top: 45%;
        display: block;

        img {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center; } }

    + .inner {
        padding-top: 7 * $base-width; }

    .inner {

        h3 {
            margin-bottom: 2 * $base-width; }

        .btn {
            margin-top: 3 * $base-width; }

        ul {
            padding-left: 6 * $base-width;

            li {
                margin-bottom: $base-width; } } } }



.bg-white + .block-text-bild {
    padding-top: 0; }
