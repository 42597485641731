.block-counter-banner {
    background-color: $primary;

    .counter-row {
        display: flex;
        flex-wrap: wrap;

        .counter_inner {
            flex: 0 0 33.33%;
            border-right: 1px solid $light;
            padding: 2 * $base-width;

            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                flex: 0 0 100%;
                border-right: 0;
                border-bottom: 1px solid $light;

                + .counter_inner {
                    margin-top: 4 * $base-width; } }

            &:last-child {
                border: 0; }

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 2 * $base-width;

                svg {
                    fill: $light;
                    height: 5rem;
                    width: 5rem; } }

            #counter {
                display: flex;
                align-items: center;
                flex-direction: column;

                .counter-value {
                    color: $light;
                    font-size: 7rem;
                    line-height: 7.8rem;
                    font-weight: 600;

                    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                        font-size: 6rem;
                        line-height: 6.8rem; } }

                p {
                    color: $light;
                    font-size: 2.4rem;
                    text-align: center;

                    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                        font-size: 2rem; } } }

            .outer-counter {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 0.25rem;

                span {
                    color: #fff; }

                .counter-percent {
                    font-size: 3em; } } } } }
