.block-medi-search {
  .medi-search-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 -3 * $base-width;

    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
      flex-wrap: wrap; }

    .col {
      padding: 3 * $base-width;
      flex: 0 0 50%;

      @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        flex: 0 0 100%; }

      .prescription, .search {
        position: relative;
        padding-top: 500px;
        border: 1px solid $bg-color;

        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
          padding-top: 450px; }

        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
           padding-top: 350px; }

        img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          z-index: -2; } }


      .text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 4 * $base-width;
        z-index: 9;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 80%;
        margin: 0 auto;
        left: 0;
        right: 0;
        background-color: rgba($light, .8);
        border: 1px solid $primary;

        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
          padding: 2 * $base-width; }

        @media only screen and (max-width: #{map-get($breakpoints, s)}) {
          width: 93%; }

        h4 {
          font-size: 2.5rem;
          margin-bottom: $base-width; }

        p {
          padding: 0; }


        form {
          text-align: center;
          display: flex;
          align-items: center;

          @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            flex-wrap: wrap;
            flex-direction: column; }

          input {
            height: 6 * $base-width;
            margin: 1.5 * $base-width 0;
            padding: 2 * $base-width;
            width: 100%;
            border: 0;
            border-radius: 25px;
            border: 1px solid $primary;
            margin-right: 2 * $base-width;

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
              margin-right: 0; }

            &:focus-visible {
              outline: 0; } }

          button {
            margin-top: $base-width;

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
              width: 100%; } } } } } } }
