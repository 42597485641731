.block-slider-medi-shop {
    background-color: $bg-color;
    padding-top: 0;

    .headline-wrapper {
        background-color: $primary;
        padding: 5 * $base-width 0;
        margin: 0 -6%;

        * {
            color: $light;
            margin-bottom: 0; } }

    .wrapper-small {
        margin-top: 5 * $base-width; }

    .owl-stage {
        display: flex; }

    .owl-nav {
        .owl-prev {
            transform: translateX(-100%);

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                transform: translateX(0%); } }

        .owl-next {
            transform: translateX(100%);

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                transform: translateX(0%); } } }

    .owl-item {
        display: flex;
        padding: 2 * $base-width;


        .medi-shop-slider-item {
            box-shadow: $box-shadow;
            background-color: $light;
            width: 100%;
            border-radius: 20px;
            margin: 3 * $base-width 0;
            padding: 8 * $base-width 4 * $base-width 4 * $base-width 4 * $base-width;
            position: relative;
            overflow: hidden;
            text-align: center;

            *:not(.btn) {
                color: $primary; }

            .btn.btn-primary {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 0 0 20px 20px;
                text-align: center;
                padding: 20px 15px; }

            .artikelbild-container {
                height: 300px;
                margin: 3 * $base-width 0;

                .artikelbild {
                    height: 100%;
                    object-fit: contain;
                    margin: auto; } }

            .beschreibung-container {
                padding: 3 * $base-width 0;
                border-top: 1px solid $bg-color;

                .artikel-preis-container {
                    display: flex;
                    justify-content: center;

                    .artikel-alter-preis {
                        padding: 0 2 * $base-width;
                        text-decoration: line-through; } }


                .artikel-grundpreis {
                    font-size: 1.5rem; } }

            .shop-rabatt-container {
                position: absolute;
                top: 0;
                left: 0;
                background-color: $primary;
                color: $light;
                padding: 5px 10px;
                font-size: 1.5rem;
                border-radius: 0 0 18px 0;

                .shop-rabatt {
                    color: $light; } } }

        .artikel-titel,
        .artikel-preis {
            font-weight: bold; }

        .artikel-preis {
            font-size: 2.2rem; }

        .artikel-gr, .artikel-nr {
            font-size: 1.5rem; } } }
