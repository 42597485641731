header {
    width: 100%;
    position: relative;
    z-index: 999;
    top: 0;
    position: fixed;
    transition: $transition;
    pointer-events: all;

    &.fixed-small {
        .navbar .main-nav-container {
            top: 87px;
            height: calc(100vh - 87px); } }


    .navbar {
        @include padding-container;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: $light;

        &__logo {
            display: block;
            flex: 0 0 250px;

            @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                display: none; }

            img {
                max-width: 250px;
                height: 10*$base-width;
                object-fit: contain;
                object-position: center; } }

        &__logo-mobil {
            display: none;

            @media (max-width: #{map-get($breakpoints, m)} ) {
                display: block; }

            img {
                height: 8 * $base-width; } }

        .navbar-inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: $base-width 0; }

        .main-nav-container {
            flex: 0 1 auto;
            position: fixed;
            width: 30%;
            right: 0;
            top: -100%;
            bottom: 0;
            z-index: 999;
            background-color: #fff;
            height: calc(100vh - 138px);
            padding: 6*$base-width 8*$base-width;
            opacity: 0;
            pointer-events: none;
            transition: $transition;
            overflow-y: auto;

            @media (max-width: #{map-get($breakpoints, m)} ) {
                width: 88%; } }

        #main-menu {
            position: relative;
            list-style: none;
            column-gap: 6*$base-width;
            display: flex;
            list-style-type: none;
            justify-content: center;
            align-items: center;
            padding-left: 0;
            column-gap: 5*$base-width;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;

            li {
                flex: 0 0 100%;
                width: 100%;

                a {
                    color: $dark;
                    font-weight: 500;
                    font-size: 1.6rem;
                    row-gap: 2rem;

                    &:hover {
                        color: $primary; } }

                &.active > a {
                    color: $primary; } }

            > li {
                padding: $base-width 0;

                &.btn-primary {
                    display: flex;
                    justify-content: center;
                    a {
                        background-color: $primary;
                        color: $light;
                        padding: 1.5 * $base-width 3 * $base-width;
                        border-radius: 30px; } }

                > a {
                    font-size: 2.2rem;
                    line-height: 125%;

                    @media (max-width: #{map-get($breakpoints, xxl)} ) {
                        font-size: 2rem; }

                    @media (max-width: #{map-get($breakpoints, s)} ) {
                        font-size: 1.8rem; } } }

            .children {
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: wrap;

                button.nav-parent-toggle {
                    width: 20px;
                    height: 20px;
                    background-color: transparent;
                    border: none;
                    margin-left: 1rem;
                    cursor: pointer;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transition: $transition; } }

                .level-1 {
                    display: flex;
                    flex-direction: column;
                    max-height: 0;
                    list-style: none;
                    background-color: $light;
                    border-radius: 15px;
                    transition: $transition;
                    box-shadow: 0px 10px 14px 0px rgba(0,0,0,0.32);
                    opacity: 0;
                    pointer-events: none;
                    z-index: 9;
                    flex: 0 0 100%;
                    width: 100%;
                    margin: 0;
                    padding: 0 0 0 2*$base-width;
                    position: relative;
                    top: unset;
                    left: unset;
                    box-shadow: none; }

                &.show {
                    img {
                        transform: rotate(-180deg);
                        transition: $transition; }

                    .level-1 {
                        max-height: 1000px;
                        opacity: 1;
                        pointer-events: all;
                        margin-top: 2rem; } }


                &:hover:not(.show) {
                    a {
                        color: $dark; }

                    img {
                        transform: unset; }

                    .level-1 {
                        max-height: 0;
                        margin-top: 0;
                        opacity: 0; } } } }


        .nav--open {
            opacity: 1;
            pointer-events: all;
            top: 148px;
            transition: $transition;

            @media (max-width: #{map-get($breakpoints, m)} ) {
                top: 138px; } }

        .navbar-toggler {
            display: none;
            align-items: center;
            justify-content: center;
            z-index: 1001;
            display: flex;

            #nav-toggler-wrapper {
                margin-right: -2 * $base-width;

                .hamburger {
                    padding: 2 * $base-width;
                    display: none;
                    cursor: pointer;
                    transition-property: opacity, filter;
                    transition-duration: 0.15s;
                    transition-timing-function: linear;
                    font: inherit;
                    color: inherit;
                    text-transform: none;
                    background-color: transparent;
                    border: 0;
                    margin: 0;
                    overflow: visible;
                    display: flex; }

                &:hover {
                    opacity: 0.7; }

                &.is-active {
                    &:hover {
                        opacity: 0.7; }

                    .hamburger-inner {
                        background-color: red; }

                    &::before, &::after {
                        background-color: red; } }

                .hamburger-box {
                    width: 40px;
                    height: 24px;
                    display: inline-block;
                    position: relative; }

                .hamburger-inner {
                    display: block;
                    top: 50%;
                    margin-top: -2px;
                    width: 40px;
                    height: 2px;
                    background-color: red;
                    border-radius: 4px;
                    position: absolute;
                    transition-property: transform;
                    transition-duration: 0.15s;
                    transition-timing-function: ease;

                    &::before, &::after {
                        width: 40px;
                        height: 2px;
                        background-color: red;
                        border-radius: 4px;
                        position: absolute;
                        transition-property: transform;
                        transition-duration: 0.15s;
                        transition-timing-function: ease; }

                    &::before, &::after {
                        content: "";
                        display: block; }

                    &::before {
                        top: -10px; }

                    &::after {
                        bottom: -10px; } }

                .hamburger--spring {
                    .hamburger-inner {
                        top: 2px;
                        transition: background-color 0s 0.13s linear;

                        &::before {
                            top: 10px;
                            transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

                        &::after {
                            top: 20px;
                            transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); } }

                    &.is-active .hamburger-inner {
                        transition-delay: 0.22s;
                        background-color: transparent !important;

                        &::before {
                            top: 0;
                            transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
                            transform: translate3d(0, 10px, 0) rotate(45deg); }

                        &::after {
                            top: 0;
                            transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
                            transform: translate3d(0, 10px, 0) rotate(-45deg); } } } } } } }

.nav-open-body-overlay {
    transition: $transition;
    opacity: 1;

    &::after {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,.44);
        width: 100%;
        height: 100%;
        z-index: 99; } }
