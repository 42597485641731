.block-gallery {

    .gallery {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -3 * $base-width;

        a {
            padding: 3 * $base-width;
            position: relative;

            &:hover {
               transform: scale(1.02); }

            &.col-2 {
                flex: 0 0 50%;
                width: 50%;

                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    flex: 0 0 100%;
                    width: 100%; } }

            &.col-3 {
                flex: 0 0 33.333%;
                width: 33.333%;

                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    flex: 0 0 50%;
                    width: 50%; }

                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    flex: 0 0 100%;
                    width: 100%; } }

            &.col-4 {
                flex: 0 0 25%;
                width: 25%;

                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    flex: 0 0 50%;
                    width: 50%; }

                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    flex: 0 0 100%;
                    width: 100%; } }

            &.col-6 {
                flex: 0 0 16.1666%;
                width: 16.666%;

                @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                    flex: 0 0 25%;
                    width: 25%; }

                @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                    flex: 0 0 50%;
                    width: 50%; }

                @media only screen and (max-width: #{map-get($breakpoints, s)}) {
                    flex: 0 0 100%;
                    width: 100%; } }

            .small {
                padding-top: $base-width; }


            picture {
                position: relative;
                padding-top: 56%;
                display: block;

                img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;

                    &.cover {
                        object-fit: cover; }

                    &.contain {
                        object-fit: contain; } } } } }

    .inner {
        @include padding-vertical;

        h3 {
            margin-bottom: 2 * $base-width; }

        .btn {
            margin-top: 3 * $base-width; }

        ul {
            padding-left: 6 * $base-width;

            li {
                margin-bottom: $base-width; } } } }
