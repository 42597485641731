
.block-service {
  .headline-wrapper {
    padding-bottom: 5 * $base-width;

    h2 {
      text-align: center; } }

  .item-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 - 3 * $base-width;

    .item {
      flex: 0 0 33%;
      width: 33%;
      padding: 3 * $base-width;
      display: flex;

      @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
        flex: 0 0 50%;
        width: 50%; }

      @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        flex: 0 0 100%;
        width: 100%; }

      .inner {
        border: 1px solid $primary;
        border-radius: 10px;
        padding: 3 * $base-width;
        text-align: center;
        transition: $transition;
        flex: 0 0 100%;
        width: 100%;

        &:hover {
          background-color: $primary;
          transition: $transition;

          .content {
            h3, p {
              color: $light;
              transition: $transition; } }

          .icon {
            background-color: $light;
            box-shadow: 0 0px 0px 8px rgba($light,0.5);
            transition: $transition;

            svg {
              fill: $primary;
              transition: $transition;

              path {
                fill: $primary;
                transition: $transition; } } } }

        &.green:hover {
          background-color: #25d366;
          border-color: #25d366;

          .content {
            h3 {
              color: $light;
              transition: $transition; } }

          .icon {
            background-color: $light;
            box-shadow: 0 0px 0px 8px rgba($light,0.5);
            transition: $transition;

            svg {
              fill: #25d366;
              transition: $transition;

              path {
                fill: #25d366;
                transition: $transition; } } } } }

      .icon {
        margin-top: 2 * $base-width;
        margin-bottom: 3 * $base-width;
        background-color: $primary;
        height: 8rem;
        width: 8rem;
        border-radius: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0px 0px 8px rgba($primary,0.5);
        transition: $transition;

        svg {
          width: 5rem;
          height: 5rem;
          fill: $light;
          transition: $transition;

          @media only screen and (max-width: #{map-get($breakpoints, s)}) {
            width: 5rem;
            height: 5rem; }

          path {
            fill: $light;
            transition: $transition; } } }

      .content {

        h3 {
          margin-bottom: $base-width;
          color: $primary;
          font-weight: 700;
          transition: $transition;

          @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
            word-break: break-word;
            hyphens: auto; } }

        p {
          padding: 0; } } }


    .small-item {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 3 * $base-width;
      // justify-content: space-evenly
      flex: 0 0 100%;
      width: 100%;
      @media only screen and (min-width: #{map-get($breakpoints, l)}) {
        flex: 0 0 33%;
        width: 33%; }
      @media only screen and (min-width: #{map-get($breakpoints, xl)}) {
        flex: 0 0 45%;
        width: 45%; }

      .inner {
        display: flex;
        align-items: center;

        .content {
          margin-left: 3 * $base-width;
          p {
            padding-bottom: 0; } }

        .icon {
          background-color: $primary;
          border-radius: 50%;
          height: 50px;
          width: 50px;
          min-width: 50px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 30px;
            fill: $light; } } } } } }
