@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    src: url('../../fonts/source-sans-pro/SourceSansPro-Light.woff2') format('woff2'),
    url('../../fonts/source-sans-pro/SourceSansPro-Light.woff') format('woff') {} }

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/source-sans-pro/SourceSansPro-Regular.woff2') format('woff2'),
    url('../../fonts/source-sans-pro/SourceSansPro-Regular.woff') format('woff') {} }

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url('../../fonts/source-sans-pro/SourceSansPro-SemiBold.woff2') format('woff2'),
    url('../../fonts/source-sans-pro/SourceSansPro-SemiBold.woff') format('woff') {} }

@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/source-sans-pro/SourceSansPro-Bold.woff2') format('woff2'),
    url('../../fonts/source-sans-pro/SourceSansPro-Bold.woff') format('woff') {} }
