.block-text-media {
    &.bg-beige {
        background-color: $bg-color; }

    .headline-wrapper {
        * {
            color: $primary;
            margin-bottom: 2 * $base-width;
            font-weight: 700; } }


    .wrapper {
        display: flex;
        align-content: stretch;
        align-items: stretch;
        flex-wrap: wrap;
        max-width: $boxed;
        margin: 0 auto;

        &.bg-beige {
            background-color: $bg-color;
            border-radius: 20px;
            overflow: hidden;

            .text {
                padding: 3 * $base-width; } }

        &.img-right {
            flex-direction: row-reverse;

            .image-banner {
                right: -4 * $base-width; } }

        &.img-left {
            flex-direction: row;

            .image-banner {
                left: -4 * $base-width; } }

        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            flex-direction: row-reverse; } }

    .text {
        align-content: center;
        align-items: center;
        flex: 0 0 55%;
        width: 55%;
        padding: 8 * $base-width;

        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            min-height: unset;
            margin-top: 4 * $base-width;
            padding: 0;
            flex: 0 0 100%;
            width: 100%; }

        &.img-right {
            padding-left: 0; }


        &.img-left {
            padding-right: 0; } }

    .media {
        z-index: 2;
        min-height: 100%;
        flex: 0 0 43%;
        width: 43%;
        padding-top: calc(56.25% / 2);
        position: relative;
        background-color: #ded7d5;

        @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            padding-top: 56.25%;
            flex: 0 0 100%;
            width: 100%; }

        &.pd-100 {
            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                padding-top: 100%;
                flex: 0 0 100%;
                width: 100%; } }


        .whatsapp-teaser {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .inner {
                padding: 30px;
                border-radius: 15px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center; }

            h3 {
                font-size: 2rem; }

            img {
                position: relative;
                object-fit: contain;
                height: 130px;
                width: 130px;
                margin: 20px 0; } }


        .image-banner {
            position: absolute;
            background-color: $primary;
            color: $light;
            padding: 2 * $base-width;
            border-radius: 10px;
            bottom: 3 * $base-width;
            z-index: 9;
            border: 2px solid $light;

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                display: none; } }


        img, iframe, video, video[poster] {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center; }

        .video-poster, .video-poster img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
            z-index: 1;
            cursor: pointer; }

        .play-button {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 80px;
            height: 80px;
            margin: auto;
            background-color: $light;
            border-radius: 100%;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            span {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 10px 0 10px 10px;
                border-color: transparent transparent transparent $primary;
                display: inline-block; } } } }
