/* Third-Party */
@import '~@fancyapps/fancybox/dist/jquery.fancybox.min';
@import '~owl.carousel/dist/assets/owl.carousel';

/* Globals */
@import './globals/vars';
@import './globals/mixins';
@import './globals/fonts';
@import './globals/base';

/* Layout-Parts */
@import './layout/typo';
@import './layout/header';
@import './layout/meta-nav';
@import './layout/footer';
@import './layout/sidebar';
@import './layout/popup';

/* Components */
@import './components/button';
@import './components/owl-carousel';

/* Blocks */
@import '../../blocks/slider-hero/style';
@import '../../blocks/info-boxes/style';
@import '../../blocks/medi-search/style';
@import '../../blocks/service/style';
@import '../../blocks/banner/style';
@import '../../blocks/contact-map/style';
@import '../../blocks/slider-magazine/style';
@import '../../blocks/mitarbeiter/style';
@import '../../blocks/text-bild/style';
@import '../../blocks/gallery/style';
@import '../../blocks/form/style';
@import '../../blocks/archive-magazine/style';
@import '../../blocks/slider-medi-shop/style';
@import '../../blocks/accordion/style';
@import '../../blocks/text-media/style';
@import '../../blocks/counter-banner/style';
@import '../../blocks/app-banner/style';
@import '../../blocks/hero-image/style';
@import '../../blocks/contact-boxes/style';
@import '../../blocks/text-columns/style';
@import '../../blocks/slider-app/style';
@import '../../blocks/slider-simple/style';
