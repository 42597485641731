.block-app-banner {
    @include padding-vertical;
    @include padding-container;
    background-color: $primary;
    position: relative;
    margin-top: 8%;
    margin-bottom: 8%;

    * {
        color: $light; }

    .image {
        picture {
            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                padding-top: 70%;
                display: block;
                position: relative;
                margin-bottom: 5 * $base-width;
                margin-top: -13 * $base-width; } }

        img {
            position: absolute;
            right: 10%;
            height: 150%;
            top: 50%;
            transform: translateY(-50%) rotate(8deg);

            @media only screen and (max-width: #{map-get($breakpoints, xxl)}) {
                height: 140%; }

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                height: 110%; }

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                transform: rotate(8deg);
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                object-fit: contain;
                object-position: center; } } }

    .wrapper {
        .inner {
            max-width: 60%;
            width: 100%;
            padding-right: 4 * $base-width;

            @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
                max-width: 55%; }

            @media only screen and (max-width: #{map-get($breakpoints, l)}) {
                max-width: 100%;
                padding: 0; }

            h3 {
                font-weight: 700; }

            .app-links {
                img {
                    height: 40px; } } } } }
