// Base
$base-width: .8rem;

// Fonts
$font-default: 'Source Sans Pro', sans-serif;

// General
$dark: #333;
$light: #fff;

// Brand Colors
$primary:  #e8583e;
$secondary: #dc8868;
$tertiary: #b3b3b3;

// Others
$bg-color: #f7f4f3;
$divider: rgba($dark, .3);
$box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.1);

$filter-primary: invert(11%) sepia(100%) saturate(5412%) hue-rotate(345deg) brightness(88%) contrast(102%);
$filter-secondary: invert(10%) sepia(95%) saturate(3864%) hue-rotate(342deg) brightness(104%) contrast(93%);
$filter-light: invert(100%) sepia(88%) saturate(2%) hue-rotate(311deg) brightness(115%) contrast(100%);

// Widths
$max: 2560px;
$full: 1920px;
$boxed: 1520px;
$small: 1200px;

// Transitions
$transition: all .3s ease-in-out;

// Breakpoints
$breakpoints: ("xs": 480px, "s": 576px, "m": 768px, "l": 992px, "xl": 1200px, "xxl": 1400px, "xxxl": 1680px, "xxxxl": 1920px);
