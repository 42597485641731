/*Variables: Form * */

$success: green;
$error: red;

.block-form {
  .wrapper {
    max-width: $small;
    margin: 0 auto;

    .form-intro {
      padding-bottom: 2 * $base-width;

      ul {
        padding-left: 6 * $base-width;

        li {
          margin-bottom: $base-width; } } }

    .form-container {

      &.contact-bar {
        display: flex;
        flex-wrap: wrap;
        margin: 0 - 4 * $base-width;

        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
           margin: 0; }

        form {
          flex: 0 0 60%;
          padding: 0 4 * $base-width;

          @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            flex: 0 0 55%; }

          @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            flex: 0 0 100%;
            padding: 0; } }

        .contact-content-teaser {
          flex: 0 0 40%;
          padding: 0 4 * $base-width;

          @media only screen and (max-width: #{map-get($breakpoints, xl)}) {
            flex: 0 0 45%; }

          @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            flex: 0 0 100%;
            margin-top: 8 * $base-width;
            padding: 0; }

          .info-wrap {
            box-shadow: 0 0 10px rgba(#000, 0.2);
            margin-bottom: 4rem;
            border-left: 5px solid $primary;
            align-items: center;
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            padding: 2 * $base-width 3 * $base-width;

            p {
              padding: 0; }

            svg {
              width: 2.7rem;
              height: 2.9rem;
              fill: $primary;
              margin-right: 2 * $base-width; } } } }


      form {
        .form-inner {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -2 * $base-width;

          .h3, h4 {
            flex: 0 0 100%;
            padding: 5 * $base-width 2 * $base-width $base-width; }


          .form-field-group {
            margin: 2 * $base-width 0;
            width: 100%;
            flex: 0 0 100%;
            padding: 0 2 * $base-width;

            &.col-6 {
              flex: 0 0 50%;
              width: 50%;

              @media only screen and (max-width: #{map-get($breakpoints, m)}) {
                width: 100%;
                flex: 0 0 100%; } }

            label {
              display: inline-block;
              margin-bottom: $base-width; }

            input[type="text"],
            input[type="date"] {
              width: 100%;
              height: 6 * $base-width;
              padding: 2 * $base-width;
              border: 1px solid #7b7b7b;
              font-family: $font-default;
              font-size: 1.6rem; }

            textarea {
              width: 100%;
              min-height: 15 * $base-width;
              padding: 2 * $base-width;
              border: 1px solid #7b7b7b;
              font-family: $font-default;
              font-size: 1.6rem; }

            .checkbox {
              display: flex;

              #dsgvo {
                width: 25px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: $base-width;

                &.error {
                  &::after {
                    content: '';
                    width: 25px;
                    height: 25px;
                    position: absolute;
                    background-color: rgba($error,.25); }

                  &:checked {
                    &::after {
                      background-color: rgba($error,.0); } } } } } }

          #confirm {
            position: absolute;
            left: -20000px; } }

        .form-success,
        .form-error {
          width: 100%;
          border: 2px solid $error;
          background-color: rgba($error,.2);
          padding: 3rem;
          margin-bottom: 3rem; }

        .form-success {
            border: 2px solid $success;
            background-color: rgba($success,.25); }

        .error {
          border-color: $error;
          background-color: rgba($error,.25); } } } } }
