.owl-carousel:not(.owl-loaded) {
    display: block;
    opacity: 0; }

.owl-nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: $full;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    transform: translateY(-50%);

    @media only screen and (max-width: #{map-get($breakpoints, m)}) {
        top: 100%;
        position: relative;
        justify-content: center;
        transform: translateY(0%); }

    .owl-prev, .owl-next {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 6 * $base-width;
        width: 6 * $base-width;
        border-radius: 10rem;
        box-shadow: rgba(0, 0, 0, 0.24) 0 0.3rem 0.8rem;
        background-color: $light !important;
        transition: $transition;

        @media only screen and (max-width: #{map-get($breakpoints, m)}) {
            margin: 0 2 * $base-width; }

        svg {
            width: 3*$base-width;
            height: 3*$base-width;
            color: $primary;
            transition: $transition; }

        &:hover svg {
            color: $secondary; } }

    .owl-prev {

        svg {
            transform: rotate(90deg); } }


    .owl-next {
        svg {
            transform: rotate(-90deg); } } }
