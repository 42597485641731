@mixin padding-vertical {
    padding-top: 10 * $base-width;
    padding-bottom: 10 * $base-width;

    @media only screen and (max-width: #{map-get($breakpoints, l)}) {
        padding-top: 8 * $base-width;
        padding-bottom: 8 * $base-width; }

    @media only screen and (max-width: #{map-get($breakpoints, s)}) {
        padding-top: 7.5*$base-width;
        padding-bottom: 7.5*$base-width; } }

@mixin padding-container {
    padding-right: 5%;
    padding-left: 5%;

    @media only screen and (max-width: #{map-get($breakpoints, xs)}) {
        padding-right: 7%;
        padding-left: 7%; } }
