/*Variables: Sidebar*/
$sidebarQuar: 8rem;
$sidebarFontSize: 1.4rem;

.sidebar {
  height: auto;
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 9999;
  right: 0;
  width: $sidebarQuar;
  top: 20%;

  @media (max-width: #{map-get($breakpoints, l)}) {
    width: 100%;
    left: 0;
    right: auto;
    bottom: -4rem;
    position: absolute;
    top: auto;
    background-color: $primary;
    justify-content: center; }

  &--hide-mobile {
    @media (max-width:  #{map-get($breakpoints, l)} ) {
      display: none; } }

  .sidebar-wrapper {
    display: flex;
    row-gap: 0.3rem;
    flex-direction: column;
    z-index: 1;

    @media (max-width: #{map-get($breakpoints, l)}) {
      width: 100%;
      flex-direction: row;
      // max-width: $mobile
      flex-wrap: wrap;
      gap: 0; } }

  a {
    color: $light;
    font-size: $sidebarFontSize;
    text-decoration: none;

    @media (max-width: #{map-get($breakpoints, l)}) {
      width: 33%; } }

  .sidebar-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: $sidebarQuar;
    width: $sidebarQuar;
    justify-content: center;
    padding: $base-width;
    background-color: $primary;
    transition: all 0.3s;

    @media (max-width: #{map-get($breakpoints, l)}) {
      flex-direction: row;
      width: 100%;
      justify-content: flex-start;
      height: 7rem; }

    &:hover {
      background-color: $secondary; }

    &__icon {
      display: inline-flex;
      padding: $base-width 0.5*$base-width 0.5*$base-width;

      svg {
        width: 2.5rem;
        height: 2.5rem;
        object-fit: contain;
        color: $light;

        @media (max-width: #{map-get($breakpoints, l)}) {
          margin-right: $base-width; } } }

    &__content {
      color: $light;
      font-size: $sidebarFontSize;
      line-height: 1.4;
      text-align: center; } } }


//Klasse für Side, wenn active für mobil
.footer-sidebar-active {
  @media (max-width:  #{map-get($breakpoints, l)} ) {
      margin-bottom: $sidebarQuar; } }
