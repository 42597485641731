.block-accordion {
  padding-right: 4 * $base-width;
  padding-left: 4 * $base-width;

  .wrapper {
    max-width: $small;
    margin: 0 auto;

    .text {
      margin-bottom: 4 * $base-width; }

    .headline-wrapper + .accordion {
      margin-top: 5 * $base-width; }

    .accordion {
      .accordion-item {
        margin-bottom: 3 * $base-width;

        &:last-child {
          margin-bottom: 0; }

        .accordion-heading {
          background-color: $light;
          border: 1px solid $primary;
          color: $primary;
          transition: $transition;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          overflow: hidden;
          border-radius: 25px;

          &:hover, &.active {
            background-color: $primary;
            color: $light;
            transition: $transition;

            .svg {
              svg {
                transform: rotate(180deg);
                transition: $transition;
                fill: $light; } } }

          .heading-text {
            padding: 2 * $base-width 3 * $base-width; }

          .svg {
            display: flex;
            align-items: center;
            padding: 2 * $base-width 3 * $base-width;

            svg {
              fill: $primary;
              width: 15px;
              height: auto;
              transition: $transition; } } }


        .accordion-body {
          padding: 3 * $base-width;

          ul {
            padding-left: 3 * $base-width; }

          a {
            text-decoration: underline; } } } } } }
